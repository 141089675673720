<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        When preparing capillary tubes for melting point analysis, what can you do to ensure the
        solid compound reaches the bottom of the tube?
      </p>

      <p v-for="option in optionsShuffled" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LBA4Q11',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {text: 'Tap the open end of the capillary tube on the bench top', value: 'open'},
        {text: 'Use a needle to physically push the sample to the bottom', value: 'needle'},
        {text: 'Use a long plastic or glass tube as a drop guide', value: 'guide'},
        {text: 'Tap the closed end of the capillary tube on the bench top.', value: 'closed'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
